import { BLOCK_COMPONENTS_ID_PREFIXES } from '../../../../../../constants/block-component-props';
import c from './block-unit-code.module.scss';

const BlockUnitCode = ({ code = '', description = null, id }) => {
  return (
    <span
      className={c.blockUnitCode}
      id={`${BLOCK_COMPONENTS_ID_PREFIXES.CODE}-${id}`}
      aria-label={`Unit code: ${code.split('').join(' ')}.`}
    >
      <abbr title={description}>{code}</abbr>
    </span>
  );
};

export default BlockUnitCode;
