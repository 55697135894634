import React, { useContext } from 'react';
import { StudentContext } from '../../../providers/StudentProvider';
import c from './header.module.scss';
import UserAvatar from './user-avatar/UserAvatar';

const Header = () => {
  const { profileData } = useContext(StudentContext);
  const pronouns = profileData?.pronouns;
  const preferredNotToSayPronouns = pronouns === 'Prefer not to say';

  return (
    <div className={c.header}>
      <UserAvatar />
      <div className={c.details}>
        <h1>{profileData?.preferredFullName || profileData?.legalName}</h1>
        {pronouns && !preferredNotToSayPronouns && <p>{pronouns}</p>}
      </div>
    </div>
  );
};

export default Header;
