import Card from '../../../../../ui/card/Card';
import c from './intermission-block.module.scss';

const IntermissionBlock = ({ block }) => {
  return (
    <div className={c.intermissionBlock}>
      <Card tabIndex="0">{block.description}</Card>
    </div>
  );
};

export default IntermissionBlock;
