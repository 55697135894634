import { Icon } from '@monash/portal-react';
import React, { useContext } from 'react';
import { MenuContext } from '@monash/portal-frontend-common';
import Card from '../../../ui/card/Card';
import Section from '../../../ui/section/Section';
import c from './quick-links.module.scss';

const QuickLinksList = [
  {
    name: 'Course maps',
    icon: Icon.File,
    link: 'https://www.monash.edu/students/handbooks/maps',
  },
  {
    name: 'Handbook',
    icon: Icon.Book,
    link: 'https://handbook.monash.edu/',
  },
  {
    name: 'Results - reading your marks',
    icon: Icon.Layout2,
    link: 'https://www.monash.edu/students/admin/exams/results',
  },
  {
    name: 'Study support',
    icon: Icon.LayoutCards,
    link: 'https://www.monash.edu/students/study-support',
  },
  {
    name: 'Census dates and teaching periods',
    icon: Icon.Calendar,
    link: 'https://www.monash.edu/students/admin/enrolments/dates/census',
  },
  {
    name: 'Summer, winter and November teaching periods',
    icon: Icon.InfoCircle,
    link: 'https://www.monash.edu/students/admin/enrolments/summer-winter',
  },
  {
    name: 'Support services',
    icon: Icon.LayoutCards,
    link: 'https://www.monash.edu/students/support',
  },
];

const QuickLinks = () => {
  const { size } = useContext(MenuContext);

  return (
    <div className={c.quickLinks}>
      <Section title="Quick links">
        <Card>
          <div className={`${c.links} ${c[size]}`}>
            {QuickLinksList.map((link) => (
              <a
                key={link.name}
                href={link.link}
                rel="noreferrer"
                target="_blank"
                aria-description=". Opens in a new window"
              >
                <link.icon size={20} color="var(--card-cta-bg-color)" />
                {link.name}
                <Icon.External size={16} color="var(--card-cta-bg-color)" />
              </a>
            ))}
          </div>
        </Card>
      </Section>
    </div>
  );
};

export default QuickLinks;
