import {
  APIContext,
  ImpersonationContext,
  courseEnrolmentStatusProps,
  sortCourseEnrolmentsByRelevance,
} from '@monash/portal-frontend-common';
import React, { useContext, useEffect, useMemo, useState } from 'react';

export const StudentContext = React.createContext();

const StudentProvider = ({ children }) => {
  // Auth
  const { currentUser } = useContext(ImpersonationContext);

  const {
    getAvatar,
    getCourseProgression,
    getProfileData,
    // errors
  } = useContext(APIContext);
  // Course progress
  const [enrolments, setEnrolments] = useState(null);
  const [loadingEnrolments, setLoadingEnrolments] = useState(false);
  const [enrolmentsError, setEnrolmentsError] = useState(null);
  // Profile data
  const [profileData, setProfileData] = useState(null);
  const [loadingProfileData, setLoadingProfileData] = useState(false);
  const [profileDataError, setProfileDataError] = useState(null);
  // User avatar
  const [avatar, setAvatar] = useState(null);
  const [loadingAvatar, setLoadingAvatar] = useState(false);
  const [avatarError, setAvatarError] = useState(null);

  useEffect(() => {
    setLoadingProfileData(true);
    getProfileData()
      .then((r) => {
        // enrich preferredName
        if (r.preferredName && r.lastName) {
          r.preferredFullName = `${r.preferredName} ${r.lastName}`;
        }
        setProfileData(r);
        setLoadingProfileData(false);
      })
      .catch((error) => {
        setProfileDataError(error);
        setLoadingProfileData(false);
      });
  }, []);

  // Fetch course progress data
  useEffect(() => {
    if (currentUser && !currentUser?.embargo && !currentUser?.hasEncumbrances) {
      setLoadingEnrolments(true);
      getCourseProgression()
        .then((r) => {
          setEnrolments(
            sortCourseEnrolmentsByRelevance(
              // Filter out invalid enrolments (eg. unconfirmed)
              r.filter(
                (enrolment) => courseEnrolmentStatusProps[enrolment.status]
              )
            )
          );
          setLoadingEnrolments(false);
        })
        .catch((error) => {
          setEnrolmentsError(error);
          setLoadingEnrolments(false);
        });
    }
  }, [currentUser]);

  // Fetch user avatar
  useEffect(() => {
    setLoadingAvatar(true);
    getAvatar()
      .then((r) => {
        setAvatar(r);
        setLoadingAvatar(false);
      })
      .catch((error) => {
        setAvatarError(error);
        setLoadingAvatar(false);
      });
  }, []);

  const values = useMemo(
    () => ({
      enrolments,
      profileData,
      avatar,
      setAvatar,
      mixAvatar: avatar?.mixAvatar,
      portalAvatar: avatar?.portalAvatar,
      fullPageError:
        profileDataError && !currentUser.hasEncumbrances && enrolmentsError,
      // authUser,
      avatarError,
      setProfileData,
      loadingProfileData,
      loadingEnrolments,
      loadingAvatar,
    }),
    [
      profileDataError,
      enrolmentsError,
      avatar,
      setAvatar,
      avatarError,
      enrolments,
      profileData,
      currentUser,
      loadingProfileData,
      loadingEnrolments,
      loadingAvatar,
    ]
  );

  return (
    <StudentContext.Provider value={values}>{children}</StudentContext.Provider>
  );
};

export default StudentProvider;
