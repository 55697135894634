import Card from '../../../ui/card/Card';
import c from './overview.module.scss';
import CreditProgress from './credit-progress/CreditProgress';
import Section from '../../../ui/section/Section';
import { useContext } from 'react';
import {
  capitaliseAllWords,
  MenuContext,
  courseEnrolmentStatusProps,
} from '@monash/portal-frontend-common';
import { getDetailsColumnCount } from './utils';

const Overview = ({ selectedCourse }) => {
  const { size } = useContext(MenuContext);

  const courseDetailsList = [
    {
      name: 'Enrolment status',
      data: selectedCourse?.status,
      display: courseEnrolmentStatusProps[selectedCourse?.status].label,
    },
    {
      name: 'Start date',
      data: selectedCourse?.enrolmentDate,
      display: new Date(selectedCourse?.enrolmentDate)
        .toLocaleDateString()
        .split('/')
        .join('-'),
    },
    {
      name: 'Location',
      data: selectedCourse?.location,
      display: capitaliseAllWords(selectedCourse?.location?.id),
    },
    {
      name: 'Study load',
      data: selectedCourse?.location,
      display: selectedCourse?.location?.attendanceType,
    },
    {
      name: 'WAM',
      data: selectedCourse?.progression.weightedAverageMark,
      display: selectedCourse?.progression.weightedAverageMark,
      hidden:
        selectedCourse?.progression.weightedAverageMarkApplicableIndicator ===
        'N',
    },
    {
      name: 'GPA',
      data: selectedCourse?.progression.gradePointAverage,
      display: selectedCourse?.progression.gradePointAverage,
      hidden:
        selectedCourse?.progression.gradePointAverageApplicableIndicator ===
        'N',
    },
  ];

  const shownCourseDetailsList = courseDetailsList.filter(
    (item) => !item.hidden
  );

  return (
    <Section title="Overview">
      <Card tabIndex="0">
        <div
          className={c.courseDetails}
          style={{
            gridTemplateColumns: `repeat(${getDetailsColumnCount(
              size,
              shownCourseDetailsList.length
            )}, 1fr)`,
          }}
        >
          {shownCourseDetailsList?.map((item, i) => (
            <div className={c.item} key={item.name}>
              <label>{item.name}</label>
              <p>
                {item.data && item.display}
                {!item.data && '\u2013'}
              </p>
            </div>
          ))}
        </div>
        {selectedCourse?.progression.creditPointsRequired > 0 && (
          <CreditProgress progress={selectedCourse.progression} />
        )}
      </Card>
    </Section>
  );
};

export default Overview;
