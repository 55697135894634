import { isStringEmpty } from '@monash/portal-frontend-common';

const GRADE_RESULT = {
  PASS: 'PASS',
  FAIL: 'FAIL',
  INCOMP: 'INCOMP',
  WITHDRAWN: 'WITHDRAWN',
};

const GRADES = {
  HD: { name: 'HIGH DISTINCTION', result: GRADE_RESULT.PASS },
  D: { name: 'DISTINCTION', result: GRADE_RESULT.PASS },
  C: { name: 'CREDIT', result: GRADE_RESULT.PASS },
  P: { name: 'PASS', result: GRADE_RESULT.PASS },
  SFR: { name: 'SATISFIED FACULTY REQUIREMENTS', result: GRADE_RESULT.PASS },
  NE: { name: 'NOT EXAMINABLE', result: GRADE_RESULT.PASS },
  NAS: { name: 'NOT ASSESSED', result: GRADE_RESULT.PASS },
  WH: { name: 'WITHHELD', result: GRADE_RESULT.INCOMP },
  DEF: { name: 'DEFERRED', result: GRADE_RESULT.INCOMP },
  NS: { name: 'SUPPLEMENTARY GRANTED', result: GRADE_RESULT.INCOMP },
  N: { name: 'FAIL', result: GRADE_RESULT.FAIL },
  NH: { name: 'HURDLE FAIL', result: GRADE_RESULT.FAIL },
  WN: { name: 'WITHDRAWN FAIL', result: GRADE_RESULT.FAIL },
  NSR: { name: 'NOT SATISFIED REQUIREMENTS', result: GRADE_RESULT.FAIL },
  WDN: { name: 'WITHDRAWN', result: GRADE_RESULT.WITHDRAWN },
  WI: { name: 'WITHDRAWN INCOMPLETE', result: GRADE_RESULT.WITHDRAWN },
  HI: { name: '1ST CLASS HONOURS', result: GRADE_RESULT.PASS },
  HIIA: { name: '2ND CLASS HONOURS DIVISION A', result: GRADE_RESULT.PASS },
  HIIB: { name: '2ND CLASS HONOURS DIVISION B', result: GRADE_RESULT.PASS },
  HIII: { name: '3RD CLASS HONOURS', result: GRADE_RESULT.PASS },
  NP: { name: 'NEAR PASS', result: GRADE_RESULT.PASS },
  DISC: { name: 'DISCONTINUED', result: GRADE_RESULT.WITHDRAWN },
  PGO: { name: 'PASS GRADE ONLY', result: GRADE_RESULT.PASS },
  NGO: { name: 'FAIL', result: GRADE_RESULT.FAIL },
  WNGO: { name: 'WITHDRAWN FAIL', result: GRADE_RESULT.FAIL },
  ER: { name: 'EXCEEDS REQUIREMENTS', result: GRADE_RESULT.PASS },
  MR: { name: 'MEETS REQUIREMENTS', result: GRADE_RESULT.PASS },
  DR: { name: 'DOES NOT MEET REQUIREMENTS', result: GRADE_RESULT.FAIL },
  MERIT: { name: 'MERIT GRADE', result: GRADE_RESULT.PASS },
  FP: { name: 'FACULTY PASS', result: GRADE_RESULT.PASS },
  NA: { name: 'NOT APPLICABLE', result: GRADE_RESULT.PASS },
  DEFOL: { name: 'DEFERRED - OPEN LEARNING', result: GRADE_RESULT.WITHDRAWN },
  DNU: { name: 'DO NOT USE (CENTRAL USE ONLY)', result: GRADE_RESULT.PASS },
  H1: { name: 'FIRST CLASS HONOURS', result: GRADE_RESULT.PASS },
  H2A: { name: 'SECOND CLASS HONOURS - A', result: GRADE_RESULT.PASS },
  H2B: { name: 'SECOND CLASS HONOURS - B', result: GRADE_RESULT.PASS },
  H3: { name: 'THIRD CLASS HONOURS', result: GRADE_RESULT.PASS },
  PD: { name: 'PASS WITH DISTINCTION', result: GRADE_RESULT.PASS },
  CD: { name: 'CONDONED DISTINCTION', result: GRADE_RESULT.PASS },
  ACICI: { name: 'ACADEMIC CREDIT - INTERNAL', result: GRADE_RESULT.PASS },
  CR: { name: 'ACADEMIC CREDIT - EXTERNAL', result: GRADE_RESULT.PASS },
  ECC: { name: 'EXEMPT DUE TO CURRIC CHANGE', result: GRADE_RESULT.PASS },
  CP: { name: 'CONDONED PASS', result: GRADE_RESULT.PASS },
  F: { name: 'FAIL', result: GRADE_RESULT.FAIL },
  SUP: { name: 'QUALIFIES FOR SUPP EXAM', result: GRADE_RESULT.INCOMP },
  FES: { name: 'FAILED EXAM SUBMINIMUM, FAIL', result: GRADE_RESULT.FAIL },
  AB: { name: 'ABSENT', result: GRADE_RESULT.FAIL },
  DNQ: { name: 'DID NOT QUALIFY FOR EXAM ENTRY', result: GRADE_RESULT.FAIL },
  A: { name: '100-80', result: GRADE_RESULT.PASS },
  B: { name: '79-75', result: GRADE_RESULT.PASS },
  E: { name: '64-60', result: GRADE_RESULT.PASS },
  G: { name: '49-0', result: GRADE_RESULT.FAIL },
  SR: { name: 'SATISFIED REQUIREMENTS', result: GRADE_RESULT.PASS },
  NYS: { name: 'REQUIREMENTS NOT YET SATISFIED', result: GRADE_RESULT.INCOMP },
};

const gradeStatus = (grade) => {
  return GRADES[grade]?.result;
};

const gradeName = (grade) => {
  return GRADES[grade]?.name;
};

const gradeScreenReaderName = (grade) => {
  const srName = GRADES[grade]?.name;
  return isStringEmpty(srName) ? '' : srName.toLowerCase();
};

const isPass = (grade) => {
  return gradeStatus(grade) === GRADE_RESULT.PASS;
};

export {
  GRADES,
  GRADE_RESULT,
  gradeStatus,
  gradeName,
  gradeScreenReaderName,
  isPass,
};
