import { BLOCK_COMPONENTS_ID_PREFIXES } from '../../../../../../constants/block-component-props';
import c from './block-credit-points-tag.module.scss';

const BlockCreditPointsTag = ({ creditPoints, id }) => {
  return (
    <div
      id={`${BLOCK_COMPONENTS_ID_PREFIXES.CREDIT_POINTS}-${id}`}
      className={c.blockCreditPointsTag}
      aria-label={`Credit points: ${creditPoints} CP.`}
    >
      {`${creditPoints} CP`}
    </div>
  );
};

export default BlockCreditPointsTag;
