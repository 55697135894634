import React from 'react';
import c from './block-description.module.scss';
import { BLOCK_COMPONENTS_ID_PREFIXES } from '../../../../../../constants/block-component-props';

const BlockDescription = ({ description, id }) => {
  return (
    <p
      className={c.blockDescription}
      id={`${BLOCK_COMPONENTS_ID_PREFIXES.DESCRIPTION}-${id}`}
      aria-label={`Unit description: ${description}.`}
    >
      {description}
    </p>
  );
};

export default BlockDescription;
