import React from 'react';
import { gradeScreenReaderName } from '../../../../course-planning/grades';
import c from './block-grade.module.scss';
import { BLOCK_COMPONENTS_ID_PREFIXES } from '../../../../../../constants/block-component-props';

const BlockGrade = ({ mark, grade, id }) => {
  const gradeSRName = gradeScreenReaderName(grade);
  const markDisplayString = grade ? `(${mark})` : mark;

  return (
    <div
      className={c.blockGrade}
      id={`${BLOCK_COMPONENTS_ID_PREFIXES.GRADE}-${id}`}
      aria-label={`Unit grade: ${mark ? mark + '.' : ''} ${gradeSRName}.`}
    >
      <span aria-hidden={true}>{grade}</span> {mark ? markDisplayString : null}
    </div>
  );
};

export default BlockGrade;
