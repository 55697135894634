import { Icon } from '@monash/portal-react';
import React, { useContext } from 'react';
import Card from '../../../ui/card/Card';
import Pronoun from '../forms/pronoun/Pronoun';
import InfoItem from '../info-item/InfoItem';
import { StudentContext } from '../../../providers/StudentProvider';

const About = () => {
  const { profileData } = useContext(StudentContext);

  const infoList = [
    {
      name: 'Name',
      icon: Icon.User,
      data: profileData?.legalName,
    },
    {
      name: 'Preferred name',
      icon: Icon.User,
      data: profileData?.preferredFullName,
    },
    {
      name: 'Pronouns',
      icon: Icon.User,
      // TODO: avoid using "-" to decide if a field is mandatory, refactor to a prop
      data: profileData?.pronouns || '-',
      form: Pronoun,
      error: profileData?.errors?.find(
        (error) =>
          error.additionalDetails ===
          'Unable to connect to http://unicrm.monash.edu'
      ),
    },
    {
      name: 'Username',
      icon: Icon.User,
      data: profileData?.username,
    },
    {
      name: 'Monash student ID',
      icon: Icon.ID,
      data: profileData?.id,
    },
    {
      name: 'Monash email address',
      icon: Icon.Mail,
      data: profileData?.email,
    },
  ];

  return (
    <Card>
      {infoList.map(
        (item) =>
          item.data && (
            <InfoItem
              name={item.name}
              error={item.error}
              icon={item.icon}
              form={item.form}
              data={item.data}
              key={item.name}
            />
          )
      )}
    </Card>
  );
};

export default About;
