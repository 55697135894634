import { BLOCK_COMPONENTS_ID_PREFIXES } from '../../../../../../constants/block-component-props';
import c from './block-status-tag.module.scss';

const BlockStatusTag = ({ blob = true, status, id }) => {
  return (
    <div
      className={c.blockStatusTag}
      id={`${BLOCK_COMPONENTS_ID_PREFIXES.STATUS}-${id}`}
      aria-label={`Unit status: ${status.label}.`}
    >
      {blob && (
        <div
          className={c.keyBlob}
          aria-hidden="true"
          style={{
            backgroundColor: `var(${status.color})`,
          }}
        />
      )}{' '}
      <span
        className={c.status}
        style={{
          color: `var(${status.color})`,
        }}
        aria-hidden="true"
      >
        {status.label}
      </span>
    </div>
  );
};

export default BlockStatusTag;
