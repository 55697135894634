import {
  BLOCK_COMPONENTS_ID_PREFIXES,
  BLOCK_ID_PREFIXES,
  BLOCK_TYPES,
} from '../../../../../constants/block-component-props';
import { getBlockStatusProps } from '../../../../utilities/functions/get-block-status-props';

export const getBlockIdWithType = (type, blockId) => {
  return `${BLOCK_ID_PREFIXES[type]}-${blockId}`;
};

// create grid block ariaLabelledby based on the current rendering logic and UI
export const getGridBlockAriaLabelledby = (block) => {
  const blockStatusProps = getBlockStatusProps(block);

  const itemsIDPrefixes = [
    block.name && BLOCK_COMPONENTS_ID_PREFIXES.CODE,
    BLOCK_COMPONENTS_ID_PREFIXES.DESCRIPTION,
    BLOCK_COMPONENTS_ID_PREFIXES.CREDIT_POINTS,
    blockStatusProps && BLOCK_COMPONENTS_ID_PREFIXES.STATUS,
    blockStatusProps &&
      (block.grade || block.mark) &&
      BLOCK_COMPONENTS_ID_PREFIXES.GRADE,
  ].filter((item) => item);

  const gridBlockAriaLabelledby = [
    getBlockIdWithType(BLOCK_TYPES.GRID, block.id),
    ...itemsIDPrefixes.map((item) => `${item}-${block.id}`),
  ].join(' ');

  return gridBlockAriaLabelledby;
};
