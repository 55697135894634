import { useContext } from 'react';
import { getBlockDescription } from '../../../../../utilities/functions/get-block-description';
import { getBlockStatusProps } from '../../../../../utilities/functions/get-block-status-props';
import BlockLabel from '../../block-components/block-label/BlockLabel';
import BlockStatusTag from '../../block-components/block-status-tag/BlockStatusTag';
import BlockCreditPointsTag from '../../block-components/block-credit-points-tag/BlockCreditPointsTag';
import c from './list-block.module.scss';
import BlockUnitCode from '../../block-components/block-unit-code/BlockUnitCode';
import BlockGrade from '../../block-components/block-grade/BlockGrade';
import BlockDescription from '../../block-components/block-description/BlockDescription';
import { MenuContext } from '@monash/portal-frontend-common';
import {
  BLOCK_COMPONENTS_ID_PREFIXES,
  BLOCK_TYPES,
} from '../../../../../../constants/block-component-props';
import { getBlockIdWithType } from '../utils';

const ListBlock = ({ block, openGuide }) => {
  const { size } = useContext(MenuContext);
  const blockStatusProps = getBlockStatusProps(block);
  const description = getBlockDescription(block);

  const items = [
    {
      label: 'Unit code',
      idPrefix: BLOCK_COMPONENTS_ID_PREFIXES.CODE,
      data: block.name,
      display: (
        <BlockUnitCode
          code={block.name}
          id={block.id}
          description={description}
        />
      ),
    },
    {
      label: 'Unit name',
      idPrefix: BLOCK_COMPONENTS_ID_PREFIXES.DESCRIPTION,
      data: description,
      display: <BlockDescription id={block.id} description={description} />,
    },
    {
      label: 'Status',
      idPrefix: BLOCK_COMPONENTS_ID_PREFIXES.STATUS,
      data: blockStatusProps,
      display: <BlockStatusTag id={block.id} status={blockStatusProps} />,
      guide: true,
    },
    {
      label: 'Grade',
      idPrefix: BLOCK_COMPONENTS_ID_PREFIXES.GRADE,
      data: block.grade || block.mark,
      display: (
        <BlockGrade mark={block.mark} id={block.id} grade={block.grade} />
      ),
    },
    {
      label: 'Credit points',
      idPrefix: BLOCK_COMPONENTS_ID_PREFIXES.CREDIT_POINTS,
      // TODO: clean up credit points data in backend
      data: block.creditPoints || true,
      display: (
        <BlockCreditPointsTag
          id={block.id}
          creditPoints={block.creditPoints || 0}
        />
      ),
    },
  ];

  // AX
  const blockId = getBlockIdWithType(BLOCK_TYPES.LIST, block.id);
  const blockAriaLabelledby = [
    blockId,
    ...items
      .filter((item) => item.data)
      .map((item) => `${item.idPrefix}-${block.id}`),
  ].join(' ');

  return (
    <div
      tabIndex="0"
      className={`${c.listBlock} ${c[size]}`}
      data-block="content"
      id={blockId}
      aria-label="Unit:"
      aria-labelledby={blockAriaLabelledby}
    >
      {items.map((item) => {
        return (
          <div
            className={c.item}
            key={item.label}
            // Using item labels for semantic grid styles
            // TODO: explore other ways that do not depend on label text
            style={{ gridArea: item.label.replace(/\s/g, '') }}
          >
            {size !== 'L' ? (
              <BlockLabel
                label={item.label}
                guide={item.guide}
                openGuide={openGuide}
              />
            ) : null}
            {!item.data && '-'}
            {item.data && item.display}
          </div>
        );
      })}
    </div>
  );
};

export default ListBlock;
