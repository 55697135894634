export const BLOCK_TYPES = {
  LIST: 'LIST',
  GRID: 'GRID',
};

export const BLOCK_ID_PREFIXES = {
  LIST: 'list-block',
  GRID: 'grid-block',
};

export const BLOCK_COMPONENTS_ID_PREFIXES = {
  CODE: 'unit-code',
  DESCRIPTION: 'unit-description',
  STATUS: 'unit-status',
  GRADE: 'unit-grade',
  CREDIT_POINTS: 'unit-credit-points',
};
